import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./components/Homepage";
import Impressum from "./components/Impressum";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import TermsAndConditions from './components/TermsAndConditions';
import CookiePolicy from "./components/CookiePolicy";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* <Route path="" element={<ComingSoon />} /> */}
        <Route path="" element={<Homepage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="impressum" element={<Impressum />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
