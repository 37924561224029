import { createStyles, Text } from "@mantine/core";
import UlettiBoxLogo from "../assets/icons/uletti-box-logo@2x.png";
import { breakpoints } from "../utils";
import AppleStoreButton from '../assets/images/apple_store.svg';
import GoogleStoreButton from '../assets/images/google_store.png';

const useStyles = createStyles(() => ({
  wrapper: {
    backgroundColor: "#1C36F3",
    display: "flex",
    justifyContent: "center",
  },

  fourthSection: {
    height: "500px",
    width: "100vw",
    maxWidth: breakpoints.l,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    overflow: "visible",
    padding: "0 32px 0 32px",
    [`@media (max-width: ${breakpoints.s})`]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100vw",
      height: "auto",
      paddingBottom: "32px",
    },
  },

  subsection1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
    },
  },

  subsection2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
      padding: "0 32px 0 32px",
    },
  },

  subsection3: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 0,
    alignItems: "center",
    width: "100%",
    marginTop: 55,
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
    },
  },

  ulettiLogo: {
    backgroundImage: `url(${UlettiBoxLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "492px",
    width: "492px",
    marginTop: "30px",
    [`@media (max-width: ${breakpoints.m})`]: {
      height: "350px",
      width: "350px",
      marginTop: "110px",
      marginLeft: "5vw",
    },
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "30px",
      height: "250px",
      width: "250px",
    },
  },

  title: {
    fontSize: "48px",
    letterSpacing: "-1.2px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    fontWeight: "bold",
    textAlign: "center",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "35px",
      letterSpacing: "-0.88px",
    },
  },

  text: {
    // marginTop: "10px",
    textAlign: "center",
    fontFamily: "Albert_Sans",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontSize: "24px",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      // marginTop: "50px",
      fontSize: "18px",
      letterSpacing: "0px",
    },
  },

  appStoreBtn: {
    height: 53,
    [`@media (max-width: ${breakpoints.s})`]: {
      height: 43,
    },
  },

  googlePlayBtn: {
    height: 78,
    [`@media (max-width: ${breakpoints.s})`]: {
      height: 63,
    },
  },

  arial: {
    fontFamily: 'Helvetica, Arial, Albert_Sans, sans-serif',
     
  }

}));

const Section4 = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.fourthSection}>
        <div className={classes.subsection1}>
          <div className={classes.ulettiLogo} />
        </div>
        <div className={classes.subsection2}>
          <Text className={classes.text}>
            Uletti is available on Google play <span className={classes.arial}>&</span> App store.
          </Text>
          <div className={classes.subsection3}>

            <a
              href="https://apps.apple.com/us/app/uletti/id6449973259?itsct=apps_box_badge&amp;itscg=30200">
              <img
                src={AppleStoreButton}
                alt="Download on the App Store"
                className={classes.appStoreBtn} />
            </a>

            <a
              href='https://play.google.com/store/apps/details?id=com.uletti.app&utm_source=uletti_web&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img
                alt='Get it on Google Play'
                src={GoogleStoreButton}
                className={classes.googlePlayBtn} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section4;
