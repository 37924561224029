import { createStyles, Text } from "@mantine/core";
import { breakpoints } from "../utils";

const useStyles = createStyles(() => ({
  wrapper: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  },

  secondSection: {
    width: "100vw",
    padding: "0 0 100px 0",
    maxWidth: breakpoints.l,
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
      flexDirection: "column",
    },
  },

  subsection1: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
  },

  title: {
    marginTop: "100px",
    textAlign: "center",
    letterSpacing: "-1.2px",
    color: "#0F1222",
    fontSize: "48px",
    fontWeight: "bold",
    fontFamily: "Albert_Sans",

    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "43px",
      fontSize: "35px",
      letterSpacing: "-0.88px",
    },
  },

  text1: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#0F1222",
    fontSize: "26px",
    fontFamily: "Albert_Sans",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "38px",
      fontSize: "18px",
    },
  },

  circle: {
    height: "21px",
    width: "21px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    borderRadius: "50%",
    backgroundColor: "#0C31FF",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "5px",
    },
  },

  leftText: {
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#0F1222",
    fontSize: "22px",
    fontFamily: "Albert_Sans",
    [`@media (max-width: ${breakpoints.s})`]: {
      fontSize: "18px",
    },
  },

  centerText: {
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#0F1222",
    fontSize: "22px",
    fontFamily: "Albert_Sans",
    [`@media (max-width: ${breakpoints.s})`]: {
      fontSize: "18px",
    },
  },

  rightText: {
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#0F1222",
    fontSize: "22px",
    fontFamily: "Albert_Sans",
    [`@media (max-width: ${breakpoints.s})`]: {
      fontSize: "18px",
    },
  },

  textContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    gap: "160px",
    padding: "32px 32px 0 32px",
    [`@media (max-width: ${breakpoints.s})`]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "32px",
      padding: "32px 32px 0 32px",
    },
  },

  textItem: {
    width: "300px",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "250px",
    },
  },
  titleContainer: {
    [`@media (max-width: ${breakpoints.s})`]: {
      padding: "0 32px",
    },
  },
  subtitleContainer: {
    width: "900px",
    [`@media (max-width: ${breakpoints.m})`]: {
      width: "600px",
    },
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "300px",
    },
  },
}));

const Section2 = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.secondSection}>
        <div className={classes.subsection1}>
          <div className={classes.titleContainer}>
            <Text className={classes.title}>Finally all in one app!</Text>
          </div>
         
        </div>

        <div className={classes.textContainer}>
          <div className={classes.textItem}>
            <div className={classes.circle} />
            <Text className={classes.leftText}>
              Download app and create new account.
            </Text>
          </div>
          <div className={classes.textItem}>
            <div className={classes.circle} />
            <Text className={classes.centerText}>
              Create your team and share team code with your friends.
            </Text>
          </div>
          <div className={classes.textItem}>
            <div className={classes.circle} />
            <Text className={classes.rightText}>
              Create match and split team members into groups.
            </Text>
          </div>
          </div>
          <div className={classes.textContainer}>
          <div className={classes.textItem}>
            <div className={classes.circle} />
            <Text className={classes.leftText}>
              Complete your team when missing one or more players for prearranged match.
            </Text>
          </div>
          <div className={classes.textItem}>
            <div className={classes.circle} />
            <Text className={classes.centerText}>
              Invite players when you are solo, you have booked playground and you want to arrange a match.
            </Text>
          </div>
          <div className={classes.textItem}>
            <div className={classes.circle} />
            <Text className={classes.rightText}>
              Add results to your match and chat with your team.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section2;
