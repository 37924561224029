import { createStyles, Text } from "@mantine/core";
import UlettiLogoText from "../assets/icons/uletti-logo-text.svg";
import UlettiLogo from "../assets/icons/uletti-logo.svg";
import Mockup2 from "../assets/images/screenshots/mockup2.png";
import { breakpoints } from "../utils";
import AppleStoreButton from '../assets/images/apple_store.svg';
import GoogleStoreButton from '../assets/images/google_store.png';
import CheckIcon from '../assets/icons/check-icon.svg';
const useStyles = createStyles(() => ({
  wrapper: {
    backgroundColor: "#0F1222",
    display: "flex",
    justifyContent: "center",
  },

  firstSection: {
    height: "800px",
    width: "100vw",
    maxWidth: breakpoints.l,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    position: "relative",
    padding: "44px 32px",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
      height: "auto",
      flexDirection: "column-reverse",
      padding: "44px 16px",
    },
  },

  subsection1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
  },

  ulettiBackground: {
    backgroundImage: `url(${UlettiLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "636px",
    width: "636px",
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
    [`@media (max-width: ${breakpoints.m})`]: {
      width: "420px",
      height: "420px",
      marginTop: "75px",
    },
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "350px",
      height: "350px",
      marginTop: "75px",
    },
    [`@media (max-width: ${breakpoints.xs})`]: {
      width: "315px",
      height: "315px",
      marginTop: "50px",
    },
  },

  mockup2: {
    marginTop: "90px",
    width: "380px",
    height: "710px",
    [`@media (max-width: ${breakpoints.m})`]: {
      width: "280px",
      height: "530px",
    },
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "215px",
      height: "389px",
      marginTop: "75px",
    },
    [`@media (max-width: ${breakpoints.xs})`]: {
      marginTop: "50px",
    },
  },

  subsection2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexWrap: "wrap",
  },

  ulettiLogoText: {
    backgroundImage: `url(${UlettiLogoText})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "152px",
    height: "50px",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "135px",
      height: "44px",
    },
  },

  subtitleContainer: {
    maxWidth: "480px",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "286px",
    },
  },
  text1: {
    textAlign:'center',
    marginTop: "72px",
    fontSize: "55px",
    letterSpacing: "-1.38px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    fontWeight: "bold",
    [`@media (max-width: ${breakpoints.m})`]: {
      fontSize: "45px",
    },
    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "55px",
      fontSize: "35px",
      letterSpacing: "-0.88px",
    },
  },

  text2: {
    textAlign:'center',
    fontSize: "26px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "15px",
      fontSize: "18px",
    },
  },

  appStores: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "80px",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "30px",
    },
  },

  appStoreBtn: {
    height: 53,
    [`@media (max-width: ${breakpoints.s})`]: {
      height: 43,
    },
  },

  googlePlayBtn: {
    height: 78,
    [`@media (max-width: ${breakpoints.s})`]: {
      height: 63,
    },
  },

  tickIcon: {
    display: 'inline-block',
    backgroundImage: `url(${CheckIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "28px",
    height: "28px",
    marginLeft: '8px',
    verticalAlign: 'text-top',
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "22px",
    height: "22px",
    },
  },

}));

const Section1 = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.firstSection}>
        <div className={classes.subsection1}>
          <div className={classes.ulettiBackground}>
            <img
              className={classes.mockup2}
              src={Mockup2}
              alt="Mobile design"
            ></img>
          </div>
        </div>
        <div className={classes.subsection2}>
          <div className={classes.ulettiLogoText} alt="Uletti logo" />
          <Text className={classes.text1}>Oh no, missing one again!</Text>
          <div className={classes.subtitleContainer}>
            <Text className={classes.text2}>
            Invite other users when you are missing players for your match.
            </Text>
          
          </div>
          <div className={classes.appStores}>


            <a
              href="https://apps.apple.com/us/app/uletti/id6449973259?itsct=apps_box_badge&amp;itscg=30200">
              <img
                src={AppleStoreButton}
                alt="Download on the App Store"
                className={classes.appStoreBtn} />
            </a>

            <a
              href='https://play.google.com/store/apps/details?id=com.uletti.app&utm_source=uletti_web&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img
                alt='Get it on Google Play'
                src={GoogleStoreButton}
                className={classes.googlePlayBtn} />
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Section1;
