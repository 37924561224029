import { createStyles, Text } from "@mantine/core";
import { Link, NavLink } from "react-router-dom";
import FacebookLogo from "../assets/icons/facebook-logo.svg";
import InstagramLogo from "../assets/icons/instagram-logo.svg";
import LinkedInLogo from "../assets/icons/linkedin-logo.svg";
import UlettiTransparentLogo from "../assets/icons/uletti-transparent-logo.svg";
import { breakpoints } from "../utils";

const useStyles = createStyles(() => ({
  wrapper: {
    backgroundColor: "#0F1222",
    display: "flex",
    justifyContent: "center",
  },

  fifthSection: {
    height: "500px",
    width: "100vw",
    maxWidth: breakpoints.l,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 40,
    [`@media (max-width: ${breakpoints.s})`]: {
      flexDirection: "column",
      width: "100vw",
      height: "auto",
    },
  },

  subsection1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    [`@media (max-width: ${breakpoints.m})`]: {
      flexDirection: "column",
    },
  },

  subsection2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: ${breakpoints.s})`]: {
      flexDirection: "column",
    },
  },

  subsection1Left: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    [`@media (max-width: ${breakpoints.m})`]: {
      width: "100%",
      flexDirection: "column",
    },
  },

  subsection1Right: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
    alignItems: "center",
    width: "50%",
    [`@media (max-width: ${breakpoints.m})`]: {
      width: "100%",
      marginTop: 86,
    },
  },

  ulettiLogo: {
    backgroundImage: `url(${UlettiTransparentLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "51px",
    height: "51px",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "55px",
      width: "80px",
      height: "80px",
    },
  },

  title: {
    marginLeft: "16px",
    fontSize: "48px",
    letterSpacing: "-1.2px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    fontWeight: "bold",
    textAlign: "center",
    [`@media (max-width: ${breakpoints.m})`]: {
      fontSize: "40px",
    },
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "27px",
      fontSize: "30px",
      letterSpacing: "-0.88px",
    },
  },

  facebookLogo: {
    backgroundImage: `url(${FacebookLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "45px",
    height: "45px",
  },

  instagramLogo: {
    backgroundImage: `url(${InstagramLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "45px",
    height: "45px",
  },

  // twitterLogo: {
  //   backgroundImage: `url(${TwitterXLogo})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "cover",
  //   width: "42px",
  //   height: "42px",
  // },

  linkedInLogo: {
    backgroundImage: `url(${LinkedInLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "52px",
    height: "52px",
  },

  contactSection: {
    display: "flex",
    justifyContent: "center",
    gap: "32px",
    alignItems: "center",
    width: "50%",
    [`@media (max-width: ${breakpoints.s})`]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "16px",
    },
  },

  legalSection: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
    alignItems: "center",
    width: "50%",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "75%",
    },
  },

  text1: {
    fontSize: "14px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: "bold",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "84px",
    },
  },

  text2: {
    fontSize: "14px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: "bold",
    [`@media (max-width: ${breakpoints.s})`]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "-5px",
    },
  },

  text3: {
    fontSize: "12px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    textAlign: "center",
    textDecoration: "none",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "364px",
      height: "31px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "40px",
    },
  },

  a: {
    textDecoration: "none",
  },
}));

const Section5 = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.fifthSection}>
        <div className={classes.subsection1}>
          <div className={classes.subsection1Left}>
            <div className={classes.ulettiLogo} />
            <Text className={classes.title}>Join Uletti community</Text>
          </div>
          <div className={classes.subsection1Right}>
            <a
              href="https://www.facebook.com/profile.php?id=61553997107258"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.facebookLogo} />
            </a>
            <a
              href="https://www.instagram.com/ulettiapp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.instagramLogo} />
            </a>
            {/* <a
              href="https://twitter.com/ulettiapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.twitterLogo} />
            </a> */}
            <a
              href="https://linkedin.com/company/uletti"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.linkedInLogo} />
            </a>
          </div>
        </div>
        <div className={classes.subsection2}>
          <div className={classes.contactSection}>
            <a href="mailto:info@uletti.com" className={classes.a}>
              <p className={classes.text1}>Contact</p>
            </a>
            <NavLink
              className={classes.text2}
              component={Link}
              to="/privacy-policy"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              className={classes.text2}
              component={Link}
              to="/terms-and-conditions"
            >
              Terms and Conditions
            </NavLink>
            <NavLink
              className={classes.text2}
              component={Link}
              to="/cookie-policy"
            >
              Cookie Policy
            </NavLink>
            <NavLink
              className={classes.text2}
              component={Link}
              to="/impressum"
            >
              Impressum
            </NavLink>
          </div>
          <div className={classes.legalSection}>
            <Text className={classes.text3}>
              {`© ${new Date().getFullYear()} Uletti Ltd, All Rights Reserved`}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section5;
