import { Table, createStyles } from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { Link, NavLink } from "react-router-dom";
import UlettiTransparentLogo from "../assets/icons/uletti-transparent-logo.svg";
import Section5 from "./Section5";

const useStyles = createStyles((theme) => ({
    cookiePolicy: {
        backgroundColor: "#0F1222",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        color: "#FFFFFF",
        fontFamily: "Albert_Sans",
        overflow: "hidden",
    },
    navigation: {
        alignItems: "center",
        display: "flex",
        minHeight: "5em",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: "5",
    },
    navigationLinksWrapper: {
        padding: "0 2.5em",
        display: "flex",
        width: "100%",
    },
    navigationBackToHomepageWrapper: {
        marginLeft: "auto",
        display: "flex",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    container: {
        margin: "0 auto",
        maxWidth: "1080px",
        position: "relative",
        width: "100%",
    },
    row: {
        display: "flex",
        width: "100%",
    },
    col: {
        boxSizing: "border-box",
        display: "flex",
        flex: "1 1",
        flexDirection: "column",
        maxWidth: "100%",
        [`@media (max-width: 1200px)`]: {
            padding: "0 2em",
        },
        [`@media (max-width: 500px)`]: {
            padding: "0 1em",
        },
    },
    backButton: {
        backgroundImage: `url(${IconArrowNarrowLeft})`,
        height: "50px",
        width: "180px",
        backgroundColor: "#FFFFFF",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "1.75rem",
        textDecoration: "none",
        fontSize: "20px",
        boxSizing: "content-box",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            height: "30px",
            width: "110px",
            padding: "4px",
        },
    },
    buttonText: {
        marginTop: "5px",
        width: "100px",
        height: "38px",
        fontSize: "20px",
        fontWeight: "500",
        color: "#000000",
        fontFamily: "Albert_Sans",
        textAlign: "center",
        textDecoration: "none",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            fontSize: "14px",
            marginTop: "15px",
        },
    },
    ulettiLogo: {
        backgroundImage: `url(${UlettiTransparentLogo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "3em",
        marginTop: "100px",
        width: "169px",
        height: "169px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    cookiePolicyTitle: {
        letterSpacing: "1.8px",
        color: "#FFFFFF",
        fontFamily: "Albert_Sans",
        fontSize: "60px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "40px",
            textAlign: "center",
        },
    },

    cookiePolicyText: {
        marginTop: "30px",
        fontSize: "20px",
        lineHeight: "22px",
        letterSpacing: "1px",
    },

    cookiePolicyAnchor: {
        color: 'white'
    },

    cookiePolicyHeading2: {
        marginTop: "50px",
        fontSize: "30px",
        lineHeight: "26px",
        letterSpacing: "2px",
    },
    cookiePolicyHeading3: {
        marginTop: "30px",
        fontSize: "25px",
        lineHeight: "24px",
        letterSpacing: "1.5px",
    },

    cookiePolicyTable: {
        marginTop: 48,
        background: "#161a2d"
    },

    cookiePolicyTableRow: {

    },

    cookiePolicyTableDef: {
        padding: 16,
        verticalAlign: 'top'
    }

}));

const CookiePolicy = () => {
    const { classes } = useStyles();
    return (
        <>
            <div className={classes.cookiePolicy}>
                <div className={classes.navigation}>
                    <div className={classes.navigationLinksWrapper}>
                        <span className={classes.navigationBackToHomepageWrapper}>
                            <NavLink component={Link} to="/" className={classes.backButton}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-arrow-narrow-left"
                                    width="44px"
                                    height="44px"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l14 0"></path>
                                    <path d="M5 12l4 4"></path>
                                    <path d="M5 12l4-4"></path>
                                </svg>
                                <p className={classes.buttonText}>homepage</p>
                            </NavLink>
                        </span>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <div className={classes.ulettiLogo} />
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h1 className={classes.cookiePolicyTitle}>
                                Uletti Cookie Policy
                            </h1>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.cookiePolicyHeading2}>
                                Our information
                            </h2>
                            <p className={classes.cookiePolicyText}>
                                We are ULETTI d.o.o. with registered seat in Zagreb, Jaruščica 1E, Registration No. 081498052 (hereinafter: "<strong>ULETTI</strong>" or "<strong>We</strong>"), and we are creators and holders of the mobile application "ULETTI" available on the App store and Google Play. Also, we are the owners of the website <a className={classes.cookiePolicyAnchor} href="https://uletti.com">www.uletti.com</a> (hereinafter: "<strong>website</strong>"). This version of the Cookie Policy applies from 21 September 2023.</p>
                            <p className={classes.cookiePolicyText}>
                                <strong>
                                    If you are a visitor or user of our website and its functionalities, this Cookie Policy applies to you, so please read it carefully.
                                </strong>
                            </p>
                            <p className={classes.cookiePolicyText}>
                                If you have any questions regarding our website, as well as questions regarding this Cookie Policy, please feel free to contact us in writing at the address of our headquarters or by e-mail at: <a className={classes.cookiePolicyAnchor} href="mailto:privacy@uletti.com">privacy@uletti.com</a>.
                            </p>
                            <p className={classes.cookiePolicyText}>
                                We will inform you about changes and/or additions to the information in the Cookie Policy in a timely manner and via our website.</p>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.cookiePolicyHeading2}>
                                Important Terms
                            </h2>
                            <p className={classes.cookiePolicyText}>
                                <strong>
                                    Cookies are small files that your internet browser stores on your computer, mobile device or any other device when you visit a website.
                                </strong>
                            </p>
                            <p className={classes.cookiePolicyText}>
                                Cookies are divided according to <strong>duration</strong>, <strong>source</strong> and <strong>function</strong> of cookies.
                            </p>
                            <p className={classes.cookiePolicyText}>
                                Depending on the <strong>duration</strong>, cookies can be <strong>permanent cookies</strong> (they remain on your computer or device from which the Internet location is visited after the Internet browser is closed) and <strong>temporary cookies</strong> (they are removed from your computer or device from which the Internet location is visited after the Internet browser is closed).
                            </p>
                            <p className={classes.cookiePolicyText}>
                                Depending on the <strong>source</strong>, cookies can be <strong>first-party cookies</strong> (stored by the original website you are primarily visiting) and <strong>third-party cookies</strong> (stored by other websites or Internet services that are part of the original website you are primarily visiting).
                            </p>
                            <p className={classes.cookiePolicyText}>
                                Depending on their <strong>function</strong>, there are several types of cookies, but the most common are <strong>technical (necessary) cookies</strong> (necessary for the functionality of the website, as well as its basic purposes of function), <strong>functional cookies</strong> (they enable the website to provide improved functionality and personalization), <strong>statistical cookies</strong> (collecting information on how do you visit the website) and <strong>marketing cookies</strong> (collect information about your habits and behaviour on the website in order to publish personalized ads).
                            </p>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.cookiePolicyHeading2}>
                                Cookies we use
                            </h2>
                            <p className={classes.cookiePolicyText}>
                                Listed below are the <strong>cookies we use on our website.</strong>
                            </p>
                            <p className={classes.cookiePolicyText}>
                                Please carefully read all about the cookies we use and, based on the information provided, decide which cookies you will enable when using our website.
                            </p>
                            <p className={classes.cookiePolicyText}>
                                <strong>As a user of our website, you are free to manage cookies and change your preferences (selections) at any time via Cookie Settings,</strong> but by disabling certain cookies, some functionalities of the website may not work properly. Also, please keep in mind that <strong>necessary cookies cannot be turned off</strong>, as these cookies are necessary for the functioning and operation of the website. These necessary cookies do not store any of your personal information which could be used to identify you.
                            </p>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h3 className={classes.cookiePolicyHeading3}>
                                Necessarry cookies
                            </h3>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>cookieyes-consent</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>1 year</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>CookieYes sets this cookie to remember users’ consent preferences so that their preferences are respected on subsequent visits to this website. It does not collect or store any personal information about the website visitors.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>Always active!</strong></td>
                                </tr>

                            </table>

                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h3 className={classes.cookiePolicyHeading3}>
                                Functional cookies
                            </h3>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_hjAbsoluteSessioninProgress</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>30 minutes</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>Hotjar sets this cookie to detect a user’s first pageview session, which is a true/false flag set by the cookie.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h3 className={classes.cookiePolicyHeading3}>
                                Analytical cookies
                            </h3>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_ga_*</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>1 year, 1 month, 4 days</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>Google Analytics sets this cooke to store and count page views.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_ga</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>1 year, 1 month, 4 days</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>Google Analytics sets this cookie to calculate visitor, session and campaign data and track website usage for the website’s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognise unique visitors.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_hjSessionUser_*</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>1 year</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>Hotjar sets this cookie to ensure data from subsequent visits to the same website is attributed to the same user ID, which persist in the Hotjar User ID, which is unique to that site.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_hjFirstSeen</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>30 minutes</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>Hotjar sets this cookie to identify a new user’s first session. It stores the true/false value, indicating whether it was the first time Hotjar saw this user.</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_hjSession_*</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>30 minutes</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>
                                        Hotjar sets this cookie to ensure data from subsequent visits to the same website is attributed to the same user ID, which persists in the HOTJAR User ID, which is unique to that website.
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_hjRecordingLast Activity</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>never</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>
                                        Hotjar sets this cookie when a user recording starts and when data is sent through the WebSocket.
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <table className={classes.cookiePolicyTable}>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                    <td className={classes.cookiePolicyTableDef}><strong>_hjRecordingEnabled</strong></td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Duration</td>
                                    <td className={classes.cookiePolicyTableDef}>never</td>
                                </tr>
                                <tr>
                                    <td className={classes.cookiePolicyTableDef}>Description</td>
                                    <td className={classes.cookiePolicyTableDef}>
                                        Hotjar sets this cookie when a recording starts and is read when the recording module is initialized, to see if the user is already in a recording in a particular session.
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                </tr>
                            </table>

                            <div className={classes.row}>
                                <div className={classes.col}>
                                    <h3 className={classes.cookiePolicyHeading3}>
                                        Uncategorized cookies
                                    </h3>

                                    <table className={classes.cookiePolicyTable}>
                                        <tr>
                                            <td className={classes.cookiePolicyTableDef}>Name of the Cookie</td>
                                            <td className={classes.cookiePolicyTableDef}><strong>_hjlncludedInSessionSample_3591749</strong></td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cookiePolicyTableDef}>Duration</td>
                                            <td className={classes.cookiePolicyTableDef}>2 minutes</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cookiePolicyTableDef}>Description</td>
                                            <td className={classes.cookiePolicyTableDef}>/</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className={classes.cookiePolicyTableDef} style={{ textAlign: 'center' }}><strong>You can manage it!</strong></td>
                                        </tr>
                                    </table>

                                </div>
                            </div>

                            <div className={classes.row}>
                                <div className={classes.col}>
                                    <h2 className={classes.cookiePolicyHeading2}>
                                        Cookie management
                                    </h2>
                                    <p className={classes.cookiePolicyText}>
                                        As a user of our website, you are free to manage cookies and change your preferences (selections) at any time via <strong>Cookie Settings</strong>.
                                    </p>
                                    <p className={classes.cookiePolicyText}>
                                        In general, <strong>you can accept (enable), reject, or delete (disable)</strong> cookies. For informational purposes, below are some basic ways to manage cookies.
                                    </p>
                                    <p className={classes.cookiePolicyText}>
                                        You can control cookies using the tools, that is the cookie and privacy settings on the website. On our website, you can find these cookie controls in the <strong>Cookie settings</strong>.
                                    </p>
                                    <p className={classes.cookiePolicyText}>
                                        By deleting your search history from your internet browser, you will also delete (remove) all stored cookies from your device. However, be aware that by doing so, you may lose some of your stored data <i>(for example, login information, personal preferences on various websites, etc.)</i>.</p>
                                    <p className={classes.cookiePolicyText}>
                                        You can set your internet browser to accept or reject cookies. If you choose to set your internet browser to reject cookies, you may be restricted or disabled from accessing certain features of the website.
                                    </p>
                                    <p className={classes.cookiePolicyText}>
                                        There are various other ways to manage cookies, so we encourage you to learn more.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                <Section5 />

            </div>
        </>
    );
};
export default CookiePolicy;
