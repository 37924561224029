import { createStyles, Text } from "@mantine/core";
import Mockup1 from "../assets/images/screenshots/mockup1.png";
import { breakpoints } from "../utils";

const useStyles = createStyles(() => ({
  wrapper: {
    backgroundColor: "#F0F1F2",
    display: "flex",
    justifyContent: "center",
  },

  thirdSection: {
    height: "726px",
    width: "100vw",
    maxWidth: breakpoints.l,
    display: "flex",
    flexDirection: "row",
    overflow: "visible",
    justifyContent: "space-evenly",

    [`@media (max-width: ${breakpoints.s})`]: {
      flexDirection: "column-reverse",
      width: "100vw",
      height: "auto",
      paddingBottom: "32px",
    },
  },

  subsection1: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: '156px',
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
      paddingLeft: '0',
    },
  },

  subsection2: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "100vw",
      marginBottom: "45px",
    },
  },

  title: {
    textAlign: "center",
    letterSpacing: "-1.32px",
    color: "#0F1222",
    fontSize: "55px",
    fontWeight: "bold",
    fontFamily: "Albert_Sans",
    [`@media (max-width: ${breakpoints.s})`]: {
      fontSize: "40px",
    },
  },

  text: {
    textAlign: "center",
    letterSpacing: "0px",
    color: "#0F1222",
    fontSize: "29px",
    fontFamily: "Albert_Sans",
    padding: '0 40px',
    [`@media (max-width: ${breakpoints.s})`]: {
      marginTop: "7px",
      fontSize: "18px",
      letterSpacing: "0px",
    },
  },

  image: {
    backgroundImage: `image-set(
      url(${Mockup1}) 1x
    )`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "850px",
    width: "455px",
    marginTop: "-30px",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    [`@media (max-width: ${breakpoints.s})`]: {
      width: "267px",
      height: "500px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "-70px",
    },
  },
}));

const Section3 = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.thirdSection}>
        <div className={classes.subsection1}>
          <Text className={classes.title}>Enter the Game</Text>
          <Text className={classes.text}>
          Uletti app for recreational athletes connects team sports and real life.
          </Text>
        </div>
        <div className={classes.subsection2}>
          <div className={classes.image} />
        </div>
      </div>
    </div>
  );
};
export default Section3;
