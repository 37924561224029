import UlettiTransparentLogo from "../assets/icons/uletti-transparent-logo.svg";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { createStyles } from "@mantine/core";
import { Link, NavLink } from "react-router-dom";
import Section5 from "./Section5";

const useStyles = createStyles((theme) => ({
  tc: {
    backgroundColor: "#0F1222",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    overflow: "hidden",
  },
  navigation: {
    alignItems: "center",
    display: "flex",
    minHeight: "5em",
    position: "absolute",
    top: "0",
    width: "100%",
    zIndex: "5",
  },
  navigationLinksWrapper: {
    padding: "0 2.5em",
    display: "flex",
    width: "100%",
  },
  navigationBackToHomepageWrapper: {
    marginLeft: "auto",
    display: "flex",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  container: {
    margin: "0 auto",
    maxWidth: "1080px",
    position: "relative",
    width: "100%",
  },
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    boxSizing: "border-box",
    display: "flex",
    flex: "1 1",
    flexDirection: "column",
    maxWidth: "100%",
    [`@media (max-width: 1200px)`]: {
      padding: "0 2em",
    },
    [`@media (max-width: 500px)`]: {
      padding: "0 1em",
    },
  },
  backButton: {
    backgroundImage: `url(${IconArrowNarrowLeft})`,
    height: "50px",
    width: "180px",
    backgroundColor: "#FFFFFF",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1.75rem",
    textDecoration: "none",
    fontSize: "20px",
    boxSizing: "content-box",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: "30px",
      width: "110px",
      padding: "4px",
    },
  },
  buttonText: {
    marginTop: "5px",
    width: "100px",
    height: "38px",
    fontSize: "20px",
    fontWeight: "500",
    color: "#000000",
    fontFamily: "Albert_Sans",
    textAlign: "center",
    textDecoration: "none",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: "14px",
      marginTop: "15px",
    },
  },
  ulettiLogo: {
    backgroundImage: `url(${UlettiTransparentLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginBottom: "3em",
    marginTop: "100px",
    width: "169px",
    height: "169px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  tcTitle: {
    letterSpacing: "1.8px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    fontSize: "60px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "40px",
      textAlign: "center",
    },
  },
  tcOrderedList: {
    display: "block",
    listStyleType: "none",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    paddingInlineStart: "40px",
  },
  tcOrderedListWithDots: {
    display: "block",
    listStyleType: "disc",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    paddingInlineStart: "40px",
  },
  tcText: {
    marginTop: "30px",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "1px",
  },
  tcSubtitle1: {
    marginTop: "50px",
    fontSize: "30px",
    lineHeight: "26px",
    letterSpacing: "2px",
  },
  tcSubtitle2: {
    marginTop: "30px",
    fontSize: "25px",
    lineHeight: "24px",
    letterSpacing: "1.5px",
    paddingInlineStart: "20px",
  },
}));

const TermsAndConditions = () => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.tc}>
        <div className={classes.navigation}>
          <div className={classes.navigationLinksWrapper}>
            <span className={classes.navigationBackToHomepageWrapper}>
              <NavLink component={Link} to="/" className={classes.backButton}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-narrow-left"
                  width="44px"
                  height="44px"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#000000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l14 0"></path>
                  <path d="M5 12l4 4"></path>
                  <path d="M5 12l4-4"></path>
                </svg>
                <p className={classes.buttonText}>homepage</p>
              </NavLink>
            </span>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.ulettiLogo} />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <h1 className={classes.tcTitle}>
                Uletti Terms and Conditions
              </h1>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Introduction
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    <b>ULETTI d.o.o.</b>, with its registered seat in Zagreb, Jaruščica 1E, Registration No. 081498052 (“<b>ULETTI</b>” or “<b>We</b>”), is the creator and the holder of the mobile application “<b>ULETTI</b>” (“<b>App</b>”) available on the App store and Google Play. This version applies from: 21 September 2023.
                  </li>
                  <li className={classes.tcText}>
                    These Terms & Conditions (“<b>T&Cs</b>”) govern your use of the App. T&Cs represent a binding agreement between you (“<b>User</b>”, “<b>you</b>” or “<b>your</b>”) and ULETTI. Please read the T&Cs carefully before creating your account. By simply creating your account, it is deemed that you have fully read and understood the T&Cs, that you are fully acquainted with and agree to the T&Cs and that you will use the App in accordance with them. In case you disagree with the T&Cs, you should delete your account and stop using the App.
                  </li>
                  <li className={classes.tcText}>
                    App offers services for arranging sports events, by creating dates and teams for individual sports (“<b>Service</b>”).
                  </li>
                  <li className={classes.tcText}>
                    T&Cs apply as of the date these have been published (see the date stated in the header). ULETTI may unilaterally amend these at any moment. If we make amendments to the T&Cs, we will provide notice of such changes in appropriate form through the App, at least 7 days before the changes come into force. The amended T&Cs will enter into force 7 days after the notification. Your continuation of use of the App after the amended T&Cs have entered into force shall mean that you have read and understood them in full and that you are familiar with and agree to them when further accessing and using the App.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI also reserves the right to, at any time, for any reason, with or without special notice, change the content and functionality of the App, as well as to shut down the App.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Use of the App
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    Any information or content posted on the App by ULETTI or otherwise marked as coming from the App administrator, is subject to copyright and other intellectual property rights of ULETTI. ULETTI retains all rights to this information and to this content.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI regularly maintains the App and takes effort that all information published there are true, correct and up to date. Nevertheless, ULETTI gives no warranties as to the truthfulness or correctness of the information published by the User.
                  </li>
                  <li className={classes.tcText}>
                    All trademarks, service marks, graphics and logos used in connection with the App, are trademarks or registered trademarks of the ULETTI or its licensors. Your use of the App grants you no right or license to reproduce or otherwise use any of the ULETTI trademarks, service marks, graphics, and logos.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI retains all rights to the App and content published on the App.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI does not grant you any express or implicit intellectual property right over the App. You shall access the App only for personal, non-commercial use.
                  </li>
                  <li className={classes.tcText}>
                    The following is strictly forbidden, without the express written consent of ULETTI:
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <ol className={classes.tcOrderedListWithDots}>
                  <li className={classes.tcText}>
                    ANY COPYING, DISTRIBUTION, TRANSMISSION, PUBLICATION, MODIFICATION, REVISION, PUBLIC DISPLAY OR MODIFICATION IN ANY OTHER WAY OF THE APP AND ITS CONTENT;
                  </li>
                  <li className={classes.tcText}>
                    Change in any way of the paper or digital copies of the App or materials published on it, which you have printed or downloaded;
                  </li>
                  <li className={classes.tcText}>
                    Use of illustrations, photographs, videos or audio sequences or any graphics published on the App or in connection with the App separately from any accompanying text;
                  </li>
                  <li className={classes.tcText}>
                    Deletion or modification of the notice of intellectual property rights or other property rights from the App or materials published in it;
                  </li>
                  <li className={classes.tcText}>
                    Using any part of the App and materials published on it for commercial purposes, without obtaining permission from ULETTI;
                  </li>
                  <li className={classes.tcText}>
                    Using reverse engineering and all other procedures that can steal the software code of the App.
                  </li>
                </ol>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    Any use of the App that is not expressly permitted by the T&Cs shall violate the T&Cs and may violate applicable regulations regarding intellectual property rights and other rights.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI neither guarantees that the App will be available 24/7 nor that all its functionalities will properly work. The App may not work properly on your device and may work differently across devices.
                  </li>
                  <li className={classes.tcText}>
                    As a User of the App, you are solely and exclusively responsible for the purchase, use and maintenance of all equipment necessary to use the App, as well as for all costs associated with the use of the App. ULETTI shall not be responsible for damage that may occur to your equipment as a result of using the App.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  User Accounts
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    The service offered on the App is intended for individual consumers. Only registered Users may enjoy and use all content and functionalities of the App.
                  </li>
                  <li className={classes.tcText}>
                    Only persons planning to use the Service should create an account.
                  </li>
                  <li className={classes.tcText}>
                    When creating an account, you should state your e-mail address and your nickname that will be added as your profile name, as well as the year of your birth and your gender (which you do not have to provide if you don’t want to).
                  </li>
                  <li className={classes.tcText}>
                    After entering the mandatory data (email address, nickname and year of birth) for registration and clicking on "Register", a User account is created, and you will receive an email that contains a 6-digit number (one time password that lasts for a certain time). After you enter the correct one-time password, the User remains logged in to the App and does not need to log in again when the App is closed (minimized) and reopened.
                  </li>
                  <li className={classes.tcText}>
                    You are responsible for maintaining the confidentiality of your nickname, password and your account, as well as all activities that occur under your account.
                  </li>
                  <li className={classes.tcText}>
                    If you become aware or reasonably suspectful of any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the password, you must immediately notify us and modify your password. You are responsible for anything that happens through your account and all uses of the password, including purchases, whether or not authorized by you.
                  </li>
                  <li className={classes.tcText}>
                    You may delete your account at any time. Any of your actions taken before the account has been deleted, remain valid. Once you delete your account, we will delete any personal data associated with this account in line with our Privacy Policy.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Safety; Your Interactions With Other Users; Reporting
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    ULETTI is not responsible for the conduct of any User on or off of the App. You agree to use caution in all interactions with other Users, particularly if you decide to communicate off the App or meet in person. You agree that you will not provide your financial information (for example, your credit card or bank account information), or wire or otherwise send money to other Users.
                  </li>
                  <li className={classes.tcText}>
                    YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU UNDERSTAND THAT ULETTI DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS. ULETTI MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OR COMPATIBILITY OF USERS.
                  </li>
                  <li className={classes.tcText}>
                    If you observe that another User is behaving in a way that violates the T&Cs or you consider that their behaviour is unacceptable, you have the option to report that User.
                  </li>
                  <li className={classes.tcText}>
                    You can submit a report through the "Report member" button found in the App or by contacting us at support@uletti.com.
                  </li>
                  <li className={classes.tcText}>
                    Please provide as much detail as possible about the inappropriate behaviour in your report so that we can better investigate the situation. This includes, but is not limited to, a description of the event, timestamps, and available evidence.
                  </li>
                  <li className={classes.tcText}>
                    Reports of inappropriate behaviour should be submitted within a reasonable time from the moment you notice irregularities, ideally within 7 days of the incident or issue.
                  </li>
                  <li className={classes.tcText}>
                    Upon receiving your report, we will investigate reported behaviour within a reasonable time. If we determine that the report is founded, we will sanction another User in accordance with the T&Cs, including suspend, disable, or delete their account.
                  </li>
                  <li className={classes.tcText}>
                    Your privacy will be protected during the investigation process, and your personal data will be processed in accordance with our Privacy Policy.
                  </li>
                  <li className={classes.tcText}>
                    Please be aware that false reports of inappropriate behaviour may lead to sanctions in accordance with our T&Cs.
                  </li>
                  <li className={classes.tcText}>
                    We kindly ask you to actively participate in maintaining a safe and pleasant environment for all Users of the App and report any behaviour you deem unacceptable. Your reports help us maintain high community standards for Users of the App.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Advertisements; Ads-Free Service
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    The App may be supported by advertising revenue and may display advertisements, promotional material, or sponsored content ("<b>Ads</b>") as part of its functionality. Some Ads may be provided by third-party advertisers or ad networks.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI does not endorse or assume any responsibility for the content, products, or services offered in these Ads. Your correspondence or business dealings with, as well as the participation in Ads found on or through the App, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, shall be solely between you and such advertiser.
                  </li>
                  <li className={classes.tcText}>
                    The Ads-free package within the App ("<b>Ads-Free Service</b>") provides Users with an ads-free experience while using the App.
                  </li>
                  <li className={classes.tcText}>
                    By subscribing to the Ads-Free Service, you agree to pay the specified one-time subscription fee.
                  </li>
                  <li className={classes.tcText}>
                    The subscription to Ads-Free Service is non-transferable and may only be used by the individual who made the purchase.
                  </li>
                  <li className={classes.tcText}>
                    ULETTI generally does not provide refunds or credits after the subscription to the Ads-Free Service was made. However, Users are subject to the app store's refund and cancellation policies and should refer to the app store for any refund requests.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Payment
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    Subscription to the Ads-free Service is an in-app purchase.
                  </li>
                  <li className={classes.tcText}>
                    By initiating an in-app purchase, you authorize the respective app store (App store or Google Play) to charge their selected payment method for the specified amount. Accepted payment methods include, but are not limited to, credit cards, PayPal, and any other methods specified in the app store's payment options.
                  </li>
                  <li className={classes.tcText}>
                    You will receive a confirmation of their in-app purchase either via email or within the app itself.
                  </li>
                  <li className={classes.tcText}>
                    In the event of billing disputes, you are advised to contact the app store directly for resolution.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Community Rules
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    By using the App, you agree that you will not:
                  </li>
                </ol>

                <ol className={classes.tcOrderedListWithDots}>
                  <li className={classes.tcText}>
                    use the App for any purpose that is illegal or prohibited by these T&Cs;
                  </li>
                  <li className={classes.tcText}>
                    use the App for any harmful or nefarious purpose;
                  </li>
                  <li className={classes.tcText}>
                    use the App in order to damage ULETTI;
                  </li>
                  <li className={classes.tcText}>
                    perform any actions that violate or will lead to a violation of the functionality of the App;
                  </li>
                  <li className={classes.tcText}>
                    submit false or misleading information;
                  </li>
                  <li className={classes.tcText}>
                    spam, solicit money from or defraud any Users;
                  </li>
                  <li className={classes.tcText}>
                    impersonate any person or entity or post any images of another person without his or her permission;
                  </li>
                  <li className={classes.tcText}>
                    bully, "stalk", intimidate, assault, harass, mistreat or defame any person;
                  </li>
                  <li className={classes.tcText}>
                    post any content that violates or infringes anyone's rights, including rights of publicity, privacy, copyright, trademark or other intellectual property or contract right;
                  </li>
                  <li className={classes.tcText}>
                    post any content that is hate speech, threatening, sexually explicit or pornographic;
                  </li>
                  <li className={classes.tcText}>
                    post any content that incites violence; or contains nudity or graphic or gratuitous violence;
                  </li>
                  <li className={classes.tcText}>
                    post any content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;
                  </li>
                  <li className={classes.tcText}>
                    solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other Users or disseminate another person's personal information without his or her permission;
                  </li>
                  <li className={classes.tcText}>
                    use another User's account, share an account with another User, or maintain more than one account;
                  </li>
                  <li className={classes.tcText}>
                    create another account if we have already terminated your account, unless you have our permission.
                  </li>
                </ol>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    We may suspend, disable, or delete your account if we determine that you have violated any provision of this T&Cs, misused the Service, behaved in a way that we regard as inappropriate or unlawful, including actions or communications that occur on or off the Service or that your conduct would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our App. We may block your email address and Internet protocol address to prevent further registration.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Objectionable content and behaviours
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    You are NOT ALLOWED to post, store, send, transmit, or disseminate any information or material which a reasonable person could deem to be objectionable, defamatory, offensive, obscene, indecent, pornographic, harassing, threatening, embarrassing, vulgar, hateful, racially, or ethnically or otherwise offensive to any group or individual or otherwise inappropriate, regardless of whether this material or its dissemination is unlawful.
                  </li>
                  <li className={classes.tcText}>
                    When using the App, you understand and acknowledge that you may be exposed to User content that is inaccurate, offensive, indecent, or objectionable. In that event, we encourage you to notify us of any such content, so that we may take appropriate measures to remove such content and measures towards such User. If you are posting any content considered objectionable, please note that ULETTI can terminate your account and delete that content.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Guarantee Waiver
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    The App and its content are put at your disposal "as is" and "as available". ULETTI and all third parties connected to ULETTI, to the fullest extent permitted by law, disclaim all guarantees, whether expressed or implied, statutory or otherwise, including, but not limited to, implied guarantees of merchantability, non-infringement of third-party rights and suitability for certain purposes.
                  </li>
                  <li className={classes.tcText}>
                    Not limited to the above, ULETTI and third parties connected to it GIVE NO REPRESENTATIONS NOR WARRANTIES about the following:
                  </li>
                </ol>

                <ol className={classes.tcOrderedListWithDots}>
                  <li className={classes.tcText}>
                    That your use of the App and its content, as well as the availability and use of all functionalities of the App, will be uninterrupted, error-free and secure;
                  </li>
                  <li className={classes.tcText}>
                    That all information, data and materials provided on the App are accurate, reliable and complete;
                  </li>
                  <li className={classes.tcText}>
                    That any interference or deficiencies with regard to the App will be corrected or removed;
                  </li>
                  <li className={classes.tcText}>
                    That any software, service, location, server or electronic communication on which the App is located or to which it is connected in any way, is free of viruses or other harmful components;
                  </li>
                  <li className={classes.tcText}>
                    That there is approval or compliance with any software tool in relation to the content of the App.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Disclaimer
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    To the maximum extent permitted by applicable regulations, ULETTI and third parties connected to it shall not be liable for any damages (ordinary damages, lost profits and non-pecuniary damage) caused by:
                  </li>
                </ol>
                <ol className={classes.tcOrderedListWithDots}>
                  <li className={classes.tcText}>
                    Your access to and use of, or inability to use the App, its content and functionalities;
                  </li>
                  <li className={classes.tcText}>
                    Any content published on the App;
                  </li>
                  <li className={classes.tcText}>
                    Any statements or conduct posted or made publicly available on the App;
                  </li>
                  <li className={classes.tcText}>
                    Your conduct or actions, as a result of your reaction to or reliance on any content and/or functionality available on the App;
                  </li>
                  <li className={classes.tcText}>
                    Loss and/or prohibition of your use of the App, or loss of access, deletion, inability to save, inability to make a copy or change any information and functionalities on the App;
                  </li>
                  <li className={classes.tcText}>
                    Any conduct or content of a third party on the App, including, but not limited to, any defamatory, offensive or illegal conduct of other Users of the App or third parties;
                  </li>
                  <li className={classes.tcText}>
                    Any other questions regarding the App, its content and functionality.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Indemnification
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    As a User of the App, you agree to indemnify ULETTI and third parties connected to it for all incurred (actual) damage and to defend and hold harmless ULETTI and third parties connected to it against and from any claims, damages, losses, costs (including reasonable legal fees), which arise directly or indirectly due to your violation of the T&Cs, or your access to and use of the App and its functionalities.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Waiver of Rights and Continuation of Rights and Obligations
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    Your failure or failure of ULETTI to exercise its rights under the T&Cs, shall not affect the validity of a part or all of the T&Cs and shall not be considered a waiver or loss of those rights or other rights under the T&Cs.
                  </li>
                  <li className={classes.tcText}>
                    Termination of these T&Cs in whole or in part, or termination of your use of the App shall not release you from any liability that has already arisen at the time of termination of the T&Cs or termination of your use of the App, or which may subsequently be determined in relation to any action or omission that occurred prior to the termination of the T&Cs or the termination of your use of the App.
                  </li>
                  <li className={classes.tcText}>
                    Any indemnification obligation in relation to the T&Cs shall be permanent, separate and independent from other fees and shall continue to apply after the termination of these T&Cs in whole or in part or the termination of your use of the App.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Severability clause
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    If any provision of these T&Cs is wholly or partially invalid or becomes wholly or partially invalid, this shall not invalidate the other provisions of the T&Cs or the T&Cs as a whole. Any invalid provision shall be replaced by a reasonable provision that is permitted by law and most closely corresponds to the business purpose of the original provision.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  No partnership
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    Unless otherwise expressly agreed in writing, nothing in these T&Cs or in your use of the App in any capacity implies the creation of any relationship between ULETTI and you, other than the relationship of you as a User of the App and ULETTI as its owner.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Term and Termination
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    These T&Cs are in force as a binding agreement between us, ULETTI d.o.o., and you, the User, until further notice as long as your account is not deactivated.
                  </li>
                  <li className={classes.tcText}>
                    You can discontinue the use of the Service at any time. We shall close your account at your request. There are some data we shall process even after the account in question is deactivated. For more detailed information, see our Privacy Policy.
                  </li>
                  <li className={classes.tcText}>
                    We can discontinue providing the Service permanently or temporarily at any time, without stating a reason or previous notice thereof. We are not liable to you or any third party for any damages or losses that result from the termination of your access to the Service.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Governing Law and Dispute Resolution
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    These T&CS shall be governed by and construed in accordance with the laws of the Republic of Croatia.
                  </li>
                  <li className={classes.tcText}>
                    If you are a consumer in the European Union, please note that you cannot be deprived of the rights granted to you by the mandatory consumer protection laws of your country of domicile.
                  </li>
                  <li className={classes.tcText}>
                    Disputes arising from these T&CS shall be resolved by the Municipal Civil Court located in Zagreb. A consumer in the European Union may, however, always institute proceedings before the competent court of his domicile.
                  </li>
                </ol>
              </div>

              <div className={classes.col}>
                <h2 className={classes.tcSubtitle1}>
                  Contacting us
                </h2>
                <ol className={classes.tcOrderedList}>
                  <li className={classes.tcText}>
                    If you have any questions, concerns, or complaints regarding this T&Cs, we encourage you to contact us using the details below: support@uletti.com.
                  </li>
                </ol>
              </div>


            </div>
          </div>

        </div>
        <Section5 />
      </div>
    </>
  );
};
export default TermsAndConditions;
