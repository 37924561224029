import UlettiTransparentLogo from "../assets/icons/uletti-transparent-logo.svg";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { createStyles } from "@mantine/core";
import { Link, NavLink } from "react-router-dom";
import Section5 from "./Section5";

const useStyles = createStyles((theme) => ({
  privacyPolicy: {
    backgroundColor: "#0F1222",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    overflow: "hidden",
  },
  navigation: {
    alignItems: "center",
    display: "flex",
    minHeight: "5em",
    position: "absolute",
    top: "0",
    width: "100%",
    zIndex: "5",
  },
  navigationLinksWrapper: {
    padding: "0 2.5em",
    display: "flex",
    width: "100%",
  },
  navigationBackToHomepageWrapper: {
    marginLeft: "auto",
    display: "flex",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  container: {
    margin: "0 auto",
    maxWidth: "1080px",
    position: "relative",
    width: "100%",
  },
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    boxSizing: "border-box",
    display: "flex",
    flex: "1 1",
    flexDirection: "column",
    maxWidth: "100%",
    [`@media (max-width: 1200px)`]: {
      padding: "0 2em",
    },
    [`@media (max-width: 500px)`]: {
      padding: "0 1em",
    },
  },
  backButton: {
    backgroundImage: `url(${IconArrowNarrowLeft})`,
    height: "50px",
    width: "180px",
    backgroundColor: "#FFFFFF",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1.75rem",
    textDecoration: "none",
    fontSize: "20px",
    boxSizing: "content-box",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: "30px",
      width: "110px",
      padding: "4px",
    },
  },
  buttonText: {
    marginTop: "5px",
    width: "100px",
    height: "38px",
    fontSize: "20px",
    fontWeight: "500",
    color: "#000000",
    fontFamily: "Albert_Sans",
    textAlign: "center",
    textDecoration: "none",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: "14px",
      marginTop: "15px",
    },
  },
  ulettiLogo: {
    backgroundImage: `url(${UlettiTransparentLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginBottom: "3em",
    marginTop: "100px",
    width: "169px",
    height: "169px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  privacyPolicyTitle: {
    letterSpacing: "1.8px",
    color: "#FFFFFF",
    fontFamily: "Albert_Sans",
    fontSize: "60px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "40px",
      textAlign: "center",
    },
  },
  privacyPolicyOrderedList: {
    display: "block",
    listStyleType: "none",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    paddingInlineStart: "40px",
  },
  privacyPolicyOrderedListWithDots: {
    display: "block",
    listStyleType: "disc",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    paddingInlineStart: "40px",
  },
  privacyPolicyUnorderedList: {
    display: "block",
    listStyleType: "disc",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    paddingInlineStart: "40px",
  },
  privacyPolicyText: {
    marginTop: "30px",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "1px",
  },
  privacyPolicyAnchor: {
    color: 'white'
  },
  privacyPolicyHeading2: {
    marginTop: "50px",
    fontSize: "30px",
    lineHeight: "26px",
    letterSpacing: "2px",
  },
  privacyPolicySubheading2: {
    marginTop: "12px",
    fontSize: "20px",
    lineHeight: "20px",
  },
  privacyPolicyHeading3: {
    marginTop: "30px",
    fontSize: "25px",
    lineHeight: "24px",
    letterSpacing: "1.5px",
  },
}));

const PrivacyPolicy = () => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.privacyPolicy}>
        <div className={classes.navigation}>
          <div className={classes.navigationLinksWrapper}>
            <span className={classes.navigationBackToHomepageWrapper}>
              <NavLink component={Link} to="/" className={classes.backButton}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-narrow-left"
                  width="44px"
                  height="44px"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#000000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l14 0"></path>
                  <path d="M5 12l4 4"></path>
                  <path d="M5 12l4-4"></path>
                </svg>
                <p className={classes.buttonText}>homepage</p>
              </NavLink>
            </span>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.ulettiLogo} />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <h1 className={classes.privacyPolicyTitle}>
                Uletti Privacy Policy
              </h1>
              <p className={classes.privacyPolicyText}>
                ULETTI d.o.o., with its registered seat in Zagreb, Jaruščica 1E, Registration No. 081498052 is the data controller. In this Privacy Policy you can find all important information about the processing and protection of your personal data, all in accordance with the provisions of the General Regulation. This version of the Privacy Policy applies from 06 February 2024.
              </p>
              <p className={classes.privacyPolicyText}>
                This Privacy Policy applies to processing of your personal data in our business processes.
              </p>
              <p className={classes.privacyPolicyText}>
                This Privacy Policy also applies to Uletti application ("App”) and sets out how we process personal data of our users. By "user” we mean any person who registered to our App.
              </p>
              <p className={classes.privacyPolicyText}>
                If you have any questions regarding the processing and protection of your personal data, as well as questions regarding this Privacy Policy, please feel free to contact us in writing at the address of our registered seat or by e-mail at the address: <a href="mailto:privacy@uletti.com" className={classes.privacyPolicyAnchor}>privacy@uletti.com</a>.
              </p>
              <p className={classes.privacyPolicyText}>
                For the purposes of this Privacy Policy, ‘we’, ‘us’, and ‘our’ refers to ULETTI d.o.o., and ‘you’ refers to you as the data subject.
              </p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Personal Data We Process
              </h2>
              <i className={classes.privacyPolicySubheading2}>when your are a candidate for working with us</i>

              <h3 className={classes.privacyPolicyHeading3}>
                Data Provided in the CV and Other Supporting Documentation
              </h3>
              <p className={classes.privacyPolicyText}>
                We only collect and process personal data you provided us through your CV and other supporting documentation, such as identification data, contact data, data on work experience etc.
              </p>

            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Legal Bases for Processing
              </h2>
              <i className={classes.privacyPolicySubheading2}>when your are a candidate for working with us</i>

              <p className={classes.privacyPolicyText}>
                We rely on several different legal bases when processing your personal data:
              </p>

              <ul className={classes.privacyPolicyUnorderedList}>
                <li className={classes.privacyPolicyText}>
                  <b>Initial communication:</b> We process your personal data for the purpose of establishing initial contact through selected channels of communication (contact data provided in your CV). In this case, we rely on our legitimate interest as legal basis for processing your personal data.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Selection of the best candidate:</b> We process your personal data for the purpose of taking the necessary actions to select the best candidate (data provided in the CV and other supporting documentation). In this case, we rely on our legitimate interest as legal basis for processing your personal data.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Keeping CVs and supporting documentation:</b> We process your personal data for the purpose of further storage (retention) of your personal data i.e., CVs and supporting documentation (data provided in the CV and other supporting documentation). In this case, we rely on your consent as legal basis for processing your personal data.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Legal duties:</b> We process your personal data for the purpose of fulfilling our legal duties i.e., compliance with applicable regulations and cooperation with competent authorities and services. In this case, we rely on our legal obligation for processing your personal data.
                </li>
              </ul>

            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Personal Data We Process
              </h2>
              <i className={classes.privacyPolicySubheading2}>when you are a responsible or contact person of our corporate clients and business partners</i>

              <h3 className={classes.privacyPolicyHeading3}>
                Data Obtained Through the Communication and Our Business Relationship
              </h3>

              <p className={classes.privacyPolicyText}>
                We only collect and process personal data you provided us during the initial communication or that we have collected during the establishment and maintenance of our business relationship, such as identification data, contact data, employment data etc.
              </p>

            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Legal Bases for Processing
              </h2>
              <i className={classes.privacyPolicySubheading2}>when you are a responsible or contact person of our corporate clients and business partners</i>

              <p className={classes.privacyPolicyText}>
                We rely on several different legal bases when processing your personal data:
              </p>

              <ul className={classes.privacyPolicyUnorderedList}>
                <li className={classes.privacyPolicyText}>
                  <b>Communication:</b> We process your personal data for the purpose of establishing initial contact, as well as for the purpose of further regular communication through selected channels of communication (contact data obtained through the communication and our business relationship). In this case, we rely on our legitimate interest as legal basis for processing your personal data.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Contractual relationship:</b> We process your personal data for the purpose of exercising the rights and obligations from our contractual relationship (identification and employment data obtained through the communication and our business relationship). In this case, we rely on our legitimate interest as legal basis for processing your personal data.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Legal duties:</b> We process your personal data for the purpose of fulfilling our legal duties i.e., compliance with applicable regulations and cooperation with competent authorities and services. In this case, we rely on our legal obligation for processing your personal data.
                </li>

              </ul>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Personal Data We Process
              </h2>
              <i className={classes.privacyPolicySubheading2}>when you are an inquiry sender</i>

              <h3 className={classes.privacyPolicyHeading3}>
                Data Provided in the Inquiry or During Our Communication
              </h3>
              <p className={classes.privacyPolicyText}>
                We only collect and process personal data you provided us in your inquiry or that we have collected during our communication, such as identification data, contact data, other data you provided to us etc.
              </p>


            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Legal Bases for Processing
              </h2>
              <i className={classes.privacyPolicySubheading2}>
                when you are an inquiry sender
              </i>

              <p className={classes.privacyPolicyText}>
                We rely on several different legal bases when processing your personal data:
              </p>

              <ul className={classes.privacyPolicyUnorderedList}>
                <li className={classes.privacyPolicyText}>
                  <b>Responding to inquiry:</b> We process your personal data for the purpose of establishing contact and answering your inquiry (data provided in the inquiry or during our communication). In this case, we rely on our legitimate interest as legal basis for processing your personal data.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Legal duties:</b> We process your personal data for the purpose of fulfilling our legal duties i.e., compliance with applicable regulations and cooperation with competent authorities and services. In this case, we rely on our legal obligation for processing your personal data.
                </li>

              </ul>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Personal Data We Process
              </h2>
              <i className={classes.privacyPolicySubheading2}>when you are an App user</i>

              <h3 className={classes.privacyPolicyHeading3}>
                Basic Account Data
              </h3>
              <p className={classes.privacyPolicyText}>
                Includes your E-mail address, your nickname that will be added as your profile name, as well as the year of your birth and your gender (which you do not have to provide if you don’t want to).
              </p>
              <p className={classes.privacyPolicyText}>
                To make it easier for your contacts and other people to reach you and recognize who you are, you may add (but you do not need to) your profile picture. There is also a possibility of setting profile picture of the team of which you are a member. Note that all your personal data that you enter on your App profile are public (visible) in our App.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Your Messages
              </h3>
              <p className={classes.privacyPolicyText}>
                Includes messages and other content (such as pictures) you publish in the chats or otherwise on the App.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Technical Data
              </h3>
              <p className={classes.privacyPolicyText}>
                Includes information about devices you use to access our App, including their type, mobile network, country, OS, date, and time of log-in / log-out and your actions in the App.
              </p>


            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Legal Bases for Processing
              </h2>
              <i className={classes.privacyPolicySubheading2}>
                when you are an App user
              </i>

              <p className={classes.privacyPolicyText}>
                We rely on several different legal bases when processing your personal data:
              </p>

              <ul className={classes.privacyPolicyUnorderedList}>
                <li className={classes.privacyPolicyText}>
                  <b>Registering to our App:</b> We process your personal data required to create and verify your user profile (Basic Account Data). In this case, we rely on our contract as legal basis for processing your profile information.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Using our App:</b> We may process your personal data to provide you access to the App, its content and to share any content you make on the App (Basic Account Data, Your Messages, Technical Data). In this case, we rely on our contract as legal basis for processing your profile information.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Getting in touch:</b> We may process your personal data to inform you about the news related to the App or to hear what you think about the App (Basic Account Data). In this case, we rely on our legitimate interest as author of the App.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Filing a request or otherwise contacting us:</b> We may process your personal data to respond to your request or otherwise answer your questions (Basic Account Data, Your Messages, Technical Data). In this case, we rely either on our legitimate interest to answer your message or on our legal obligation to respond to your request.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Understanding how you use our services:</b> We may process your personal data so that we could further develop the App and provide you with even better experience (Basic Account Data, Your Messages, Technical Data). In this case, we rely on our legitimate interest as author of the App.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Personalized ads and content and their measurement:</b> We may process your personal data by storing them, providing access, and sharing them with third–party providers to show you basic ads, to select personalized ads and content, to create personalized ads and content profile, and to measure ads and content performance (Basic Account Data and Technical Data). In this case, we rely on your consent as legal basis for processing.
                </li>

                <li className={classes.privacyPolicyText}>
                  <b>Security incident or unauthorised use of the App:</b> We may process your personal data so that we could detect, prevent, or otherwise address any security incident or unauthorised use of the App (Basic Account Data, Your Messages, Technical Data). In this case, we rely on our legitimate interest as author of the App.
                </li>

              </ul>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Our Main Principles When Using Our App
              </h2>

              <h3 className={classes.privacyPolicyHeading3}>
                Data Minimisation
              </h3>
              <p className={classes.privacyPolicyText}>
                We only ask you for minimal set of data – we do not need to know more about you than what is necessary to run our services. We do not even need to know your real name, just your E -mail address, nickname and year of birth.
              </p>
              <p className={classes.privacyPolicyText}>
                To make it easier for your contacts and other people to reach you and recognize who you are, you may add (but you do not need to) your profile picture. There is also a possibility of setting profile picture of the team of which you are a member. Note that all your personal data that you enter on your App profile are public (visible) in our App.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Deleting Profile
              </h3>
              <p className={classes.privacyPolicyText}>
                Deleting your profile means deleting your personal data. As simple as that. You can delete your profile within our App. If you decide to delete your profile, that action is permanent.
              </p>
              <p className={classes.privacyPolicyText}>
                After deleting your profile, the content you published remains in the App.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Disturbing Users
              </h3>
              <p className={classes.privacyPolicyText}>
                We have a feature for reporting users for harassment or inappropriate behaviour. If you find actions of some users inappropriate, please contact us via App through option „Report User“.
              </p>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Legitimate Interest as the Legal Basis
              </h2>


              <p className={classes.privacyPolicyText}>
                We use legitimate interest as the legal basis for certain processing of your personal data. Prior to the processing of your personal based on legitimate interest as the legal basis, we take into account your interests and fundamental rights and freedoms, as well as your reasonable expectations about the processing of your personal data in our mutual relationship.
              </p>

              <p className={classes.privacyPolicyText}>
                To prove the existence of our legitimate interest, we conduct the legitimate interest assessment separately for each personal data processing where legitimate interest is the legal ground of processing.
              </p>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Social Networks
              </h2>


              <p className={classes.privacyPolicyText}>
                We have accounts on some social networks, which can be accessed through links on our website www.uletti.com.
              </p>

              <p className={classes.privacyPolicyText}>

                We have accounts on the following social networks: <a href="https://www.facebook.com/profile.php?id=61553997107258" className={classes.privacyPolicyAnchor}>https://www.facebook.com/profile.php?id=61553997107258</a>, <a href="https://www.instagram.com/ulettiapp" className={classes.privacyPolicyAnchor}>https://www.instagram.com/ulettiapp</a>, <a href="https://linkedin.com/company/uletti" className={classes.privacyPolicyAnchor}>https://linkedin.com/company/uletti</a> for the purpose of promoting the App, our activities, getting in touch and communicating with users of the App, as well as with the potential and existing clients and business partners, and the like.
              </p>

              <p className={classes.privacyPolicyText}>
                All information and materials that you provide to us through social networks, as well as all communication that takes place through social networks, is done at your own risk. We are not responsible for the actions of social network users, nor for the actions of the social network itself. Your interaction with the social network in relation to the processing of your personal data is governed by the privacy policy of that social network.
              </p>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Sharing Your Personal Data
              </h2>

              <h3 className={classes.privacyPolicyHeading3}>
                Our Subcontractors
              </h3>
              <p className={classes.privacyPolicyText}>
                We use services of third-party providers and affiliated companies for certain activities, including, but not limited to hosting, storing, marketing, and data analytics. When processing your personal data, our service providers will act in line with our instructions and will not process your personal data for any purpose other than the ones stated in this Privacy Policy.
              </p>


              <h3 className={classes.privacyPolicyHeading3}>
                Data Transfers
              </h3>
              <p className={classes.privacyPolicyText}>
                In case you are a user located in the EU, you may want to know that we store your personal data within the EU.
              </p>
              <p className={classes.privacyPolicyText}>
                Third parties with whom we share your personal data may be based outside the EU/EEA and, as such, the processing of your personal data may involve a transfer of your personal data outside the EU/EEA.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Other Uletti Users
              </h3>
              <p className={classes.privacyPolicyText}>
                Other users of our services may gain access to your nickname, e-mail address and other data that are visible on your profile and the content you communicated with that user. Note that some of our users may be located outside the EU/EEA.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Law Enforcement Authorities
              </h3>
              <p className={classes.privacyPolicyText}>
                If we receive a court order to share your data, we are under duty to disclose requested data to the relevant authorities. We keep register of such requests and will inform the affected data subjects as soon as we are allowed to.
              </p>


            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Advertising
              </h2>


              <p className={classes.privacyPolicyText}>
                While using our App, you may encounter advertisements that are selected and displayed on your device based on predictions about your interests. This form of ad customization is often referred to as 'online behavioural' or 'interest-based' advertising.
              </p>

              <p className={classes.privacyPolicyText}>
                You can find the list of third-party providers who might have access and use your data to deliver the advertising in our App (please go to Privacy – Privacy Settings – Third Party Vendors). For more details on how they process and protect your data, please consult their websites and individual privacy statements.</p>

              <p className={classes.privacyPolicyText}>
                You can find out more information regarding the topic in question and manage your preferences via Privacy Settings in our App.
              </p>



            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Data Retention Periods
              </h2>


              <p className={classes.privacyPolicyText}>
                Data retention periods vary depending on the categories of personal data that we process, the purposes and legal grounds of the processing of your personal data (criteria we use when calculating data retention periods).
              </p>

              <p className={classes.privacyPolicyText}>
                Below are the general data retention periods, but please be aware that these periods may vary depending on the specific processing situations.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Retention Period Defined by the Applicable Regulation
              </h3>
              <p className={classes.privacyPolicyText}>
                We store your personal data in the period defined by the applicable regulations and delete them in an additional period of 3 (three) months.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                When We Have Signed a Contract with You
              </h3>
              <p className={classes.privacyPolicyText}>
                We store your personal data for the entire duration of our contractual relationship and delete them within an additional period of 3 (three) months from the date of termination of the contractual relationship.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Legitimate Interest as Legal Basis for Processing
              </h3>
              <p className={classes.privacyPolicyText}>
                We store your personal data for the entire period of existence of our legitimate interest and delete them within an additional period of 3 (three) months from the termination of existence of our legitimate interest.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Consent as Legal Basis for Processing
              </h3>
              <p className={classes.privacyPolicyText}>
                We store your personal data until you withdraw your consent. When you withdraw your consent, we delete your personal data as soon as possible. If you have given us your consent for a certain period, at the end of this period, we will delete your personal data as soon as possible.
              </p>




            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Your Rights
              </h2>


              <p className={classes.privacyPolicyText}>
                As a data subject whose personal data we process, you can exercise the rights listed and described below. You can exercise your rights by sending a request to the address of our registered seat or by e-mail at the address: <a href="mailto:privacy@uletti.com" className={classes.privacyPolicyAnchor}>privacy@uletti.com</a>.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right of Access
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right to obtain the confirmation as to whether we are processing your personal data and, if we do, access to your personal data and relevant information.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Rectification
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right to obtain the rectification of your inaccurate personal data. You have the right to have incomplete personal data completed including by means of providing a supplementary statement.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Erasure ("Right to be Forgotten")
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right to obtain the erasure of your personal data if one of the conditions from Article 17(1) of the General Regulation is met.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Restriction of Processing
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right to obtain a restriction of the processing of your personal data if one of the conditions from Article 18(1) of the General Regulation is met.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Data Portability
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right to receive your personal data in a structured, commonly used, and machine-readable format, and to transfer them to another controller if the processing of your personal data is based on consent or a contract, and the processing is carried out by automated means.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Withdraw Consent
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right to withdraw your consent at any time.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Object
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right, based on your particular situation, to object to the processing of your personal data, which we process based on our legitimate interest and / or for direct marketing purposes, which includes creating a profile.
              </p>

              <h3 className={classes.privacyPolicyHeading3}>
                Right to Object to a Supervisory Authority
              </h3>
              <p className={classes.privacyPolicyText}>
                You have the right at any time to object to an independent public authority for the protection of personal data. The independent public authority in the Republic of Croatia is the Personal Data Protection Agency (AZOP), with its registered seat at Selska cesta 136, 10000 Zagreb, Croatia. You can find more information about AZOP on their website <a href="www.azop.hr" className={classes.privacyPolicyAnchor}>www.azop.hr</a>.
              </p>

            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Third - Party Links
              </h2>


              <p className={classes.privacyPolicyText}>
                Our website www.uletti.com and our App may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website www.uletti.com or our App, we encourage you to read the privacy policy of every website you visit or third-party mobile application you may use.
              </p>
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <h2 className={classes.privacyPolicyHeading2}>
                Changes to this Privacy Policy
              </h2>


              <p className={classes.privacyPolicyText}>
                We will review and may update this Privacy Policy from time to time. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy. Please check our website www.uletti.com and our App to see any updates or changes to our Privacy Policy, a summary of which we will set out below.
              </p>
            </div>
          </div>


        </div>
        <Section5 />
      </div>
    </>
  );
};
export default PrivacyPolicy;
