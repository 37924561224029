import UlettiTransparentLogo from "../assets/icons/uletti-transparent-logo.svg";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { createStyles } from "@mantine/core";
import { Link, NavLink } from "react-router-dom";
import Section5 from "./Section5";

const useStyles = createStyles((theme) => ({
    impressum: {
        backgroundColor: "#0F1222",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        color: "#FFFFFF",
        fontFamily: "Albert_Sans",
        overflow: "hidden",
    },
    navigation: {
        alignItems: "center",
        display: "flex",
        minHeight: "5em",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: "5",
    },
    navigationLinksWrapper: {
        padding: "0 2.5em",
        display: "flex",
        width: "100%",
    },
    navigationBackToHomepageWrapper: {
        marginLeft: "auto",
        display: "flex",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    container: {
        margin: "0 auto",
        maxWidth: "1080px",
        position: "relative",
        width: "100%",
    },
    row: {
        display: "flex",
        width: "100%",
    },
    col: {
        boxSizing: "border-box",
        display: "flex",
        flex: "1 1",
        flexDirection: "column",
        maxWidth: "100%",
        [`@media (max-width: 1200px)`]: {
            padding: "0 2em",
        },
        [`@media (max-width: 500px)`]: {
            padding: "0 1em",
        },
    },
    backButton: {
        backgroundImage: `url(${IconArrowNarrowLeft})`,
        height: "50px",
        width: "180px",
        backgroundColor: "#FFFFFF",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "1.75rem",
        textDecoration: "none",
        fontSize: "20px",
        boxSizing: "content-box",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            height: "30px",
            width: "110px",
            padding: "4px",
        },
    },
    buttonText: {
        marginTop: "5px",
        width: "100px",
        height: "38px",
        fontSize: "20px",
        fontWeight: "500",
        color: "#000000",
        fontFamily: "Albert_Sans",
        textAlign: "center",
        textDecoration: "none",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            fontSize: "14px",
            marginTop: "15px",
        },
    },
    ulettiLogo: {
        backgroundImage: `url(${UlettiTransparentLogo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "3em",
        marginTop: "100px",
        width: "169px",
        height: "169px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    impressumTitle: {
        letterSpacing: "1.8px",
        color: "#FFFFFF",
        fontFamily: "Albert_Sans",
        fontSize: "60px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "40px",
            textAlign: "center",
        },
    },
    impressumOl: {
        display: "block",
        listStyleType: "none",
        marginBlockStart: "1em",
        marginBlockEnd: "1em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        paddingInlineStart: "30px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            paddingInlineStart: "0px",
        },
    },

    impressumText: {
        marginTop: "30px",
        fontSize: "20px",
        lineHeight: "22px",
        letterSpacing: "1px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
        },
    },
    impressumSubtitle: {
        marginTop: "50px",
        fontSize: "30px",
        lineHeight: "30px",
        letterSpacing: "2px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
        },
    }
}));

const Impressum = () => {
    const { classes } = useStyles();
    return (
        <>
            <div className={classes.impressum}>
                <div className={classes.navigation}>
                    <div className={classes.navigationLinksWrapper}>
                        <span className={classes.navigationBackToHomepageWrapper}>
                            <NavLink component={Link} to="/" className={classes.backButton}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-arrow-narrow-left"
                                    width="44px"
                                    height="44px"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l14 0"></path>
                                    <path d="M5 12l4 4"></path>
                                    <path d="M5 12l4-4"></path>
                                </svg>
                                <p className={classes.buttonText}>homepage</p>
                            </NavLink>
                        </span>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <div className={classes.ulettiLogo} />
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h1 className={classes.impressumTitle}>
                                Uletti Impressum
                            </h1>

                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Company name
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        ULETTI d.o.o.
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Registered Seat Address
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        Jaruščica 1E, 10 020 Zagreb, Croatia
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Croatian PIN (OIB)
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        02553613675
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Court of Registration
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        Commercial Court in Zagreb
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Registration Number (MBS)
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        081498052
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                CEO
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        Vedran Turković
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Bank
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        Privredna banka Zagreb P.L.C., Radnička cesta 50, Zagreb, Croatia
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Bank Account No.
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        HR0923400091111226150
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h2 className={classes.impressumSubtitle}>
                                Share Captial (paid in full)
                            </h2>
                            <div className={classes.col}>
                                <ol className={classes.impressumOl}>
                                    <p className={classes.impressumText}>
                                        2.500,00 EUR
                                    </p>
                                </ol>
                            </div>
                        </div>
                    </div>



                </div>
                <Section5 />
            </div>
        </>
    );
};
export default Impressum;
